import React from "react"
import { Link } from "gatsby";
export default class Masthead extends React.Component {
    render() {
        return (<header className="masthead bg-primary text-white text-center">
        <div className="container d-flex align-items-center flex-column">
          {/* <!-- Masthead Avatar Image--> */}
          <img className="masthead-avatar mb-5" src="assets/img/avataaars.svg" alt="" />
          {/* <!-- Masthead Heading--> */}
          <h1 className="masthead-heading text-uppercase mb-0" style={{color: 'rgb(255, 255, 255)'}}>Homepages are lame!</h1>
          {/* <!-- Icon Divider--> */}
          <div className="divider-custom divider-light">
            <div className="divider-custom-line"></div>
            <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
            <div className="divider-custom-line"></div>
          </div>
          {/* <!-- Masthead Subheading--> */}
          <p className="masthead-subheading font-weight-light mb-0">Programmer - Musician - Action sports enthusiast.</p>
          <p className="masthead-subheading font-weight-light mb-0"> Developing <a href="https://store.steampowered.com/app/1771240/Piano_Rocker/?beta=1">PIANO ROCKER - The ultimate piano game</a></p>
          <p className="masthead-subheading font-weight-light mb-0"> Writing for <a href="https://berlin-action-boys.com/">Berlin action boys</a></p>
          <p className="masthead-subheading font-weight-light mb-0">
              Also writing another personal blog <Link style={{ padding: '0 0.2rem', textDecoration: 'underline', color: 'white' }} target="_blank"  to="en/en-index" > right here </Link>
          </p>
        </div>
      </header>)
    }
}
