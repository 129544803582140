import React from "react"

export default class About extends React.Component {
    render() {
        return (
            <section className="page-section bg-primary text-white mb-0" id="about">
                <div className="container">
                    {/* <!-- About Section Heading--> */}
                    <h2 className="page-section-heading text-center text-uppercase text-white">About</h2>
                    {/* <!-- Icon Divider--> */}
                    <div className="divider-custom divider-light">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                        <div className="divider-custom-line"></div>
                    </div>
                    {/* <!-- About Section Content--> */}
                    <div className="row">
                        <div className="col-lg-4 ml-auto"><span className="lead">
                            <p>Developing software professionally since 2013.</p>
                            <p>I was born in Ukraine, where the full-scale war is waging now. If you want Ukraine to prevail - <a href="https://savelife.in.ua/en/">help by donating to "Come back alive" fund.</a></p>
                        </span></div>
                        <div className="col-lg-4 mr-auto">
                            <span className="lead">
                                This page was built using <a style={{ textDecoration: 'underline', color: 'white' }} href="https://www.gatsbyjs.com/">Gatsby</a>. The styles are from 
                                <a style={{ padding: '0 0.2rem', textDecoration: 'underline', color: 'white' }} href="https://startbootstrap.com/previews/freelancer">this template</a>
                                <p></p> 
                                <p>Perhaps there is sometihng i could do for you or your business - contact me, I'm happy to discuss it.</p>
                            </span></div>
                    </div>
                    {/* <!-- About Section Button--> */}
                    <div className="text-center mt-4">
                        <a className="btn btn-xl btn-outline-light" target="_blank" href="https://drive.google.com/file/d/1TroT9cO8sfndiigfEiarU6xgd21FKXy8/view?usp=sharing">
                            <i className="fas fa-download mr-2"></i>
                          Click here to download my CV
                      </a>
                    </div>
                </div>
            </section>
        )
    }
}
