import React from "react"
import Meta from "../component/meta"

import Helmet from 'react-helmet'
import Masthead from '../component/landing/masthead';
import Navbar from '../component/landing/navbar';
import Portfolio from '../component/landing/portfolio';
import About from '../component/landing/about';
import Contact from '../component/landing/contact';
export default class IndexPage extends React.Component {
  closeMyCuteWindow() {
    document.querySelector('#myCuteWindow').remove();
  }

  componentDidMount() {
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Montserrat:400,700" rel="stylesheet" type="text/css" />
          <link href="https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic" rel="stylesheet" type="text/css" />
          <link href="landing.css" rel="stylesheet" />
        </Helmet>
        <Meta
          title="Рома Гуйван: Моя домашня сторінка і блог | Roman Guivan: Homepage and blog"
          keywords={[]}
        ></Meta>
        <Navbar></Navbar>
        <Masthead></Masthead>
        <Portfolio></Portfolio>
        <About></About>
        <div className="copyright py-4 text-center text-white">
          <div className="container"><small>Copyright © Roman Guivan 2023</small></div>
        </div>
        {/* <!-- Scroll to Top Button (Only visible on small and extra-small screen sizes)--> */}
        <div className="scroll-to-top d-lg-none position-fixed">
          <a className="js-scroll-trigger d-block text-center text-white rounded" href="#page-top"><i className="fa fa-chevron-up"></i></a>
        </div>
        <div className="portfolio-modal modal fade" id="portfolioModal1" tabIndex="-1" role="dialog" aria-labelledby="portfolioModal1Label" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i className="fas fa-times"></i></span>
              </button>
              <div className="modal-body text-center">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-8">
                      {/* <!-- Portfolio Modal - Title--> */}
                      <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0" id="portfolioModal1Label">Log Cabin</h2>
                      {/* <!-- Icon Divider--> */}
                      <div className="divider-custom">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                        <div className="divider-custom-line"></div>
                      </div>
                      {/* <!-- Portfolio Modal - Image--> */}
                      <img className="img-fluid rounded mb-5" src="assets/img/portfolio/cabin.png" alt="" />
                      {/* <!-- Portfolio Modal - Text--> */}
                      <p className="mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Mollitia neque assumenda ipsam nihil, molestias magnam, recusandae quos quis inventore quisquam velit asperiores, vitae? Reprehenderit soluta, eos quod consequuntur itaque. Nam.</p>
                      <button className="btn btn-primary" data-dismiss="modal">
                        <i className="fas fa-times fa-fw"></i>
                                        Close Window
                                    </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>);

  }
}