import React from "react"

export default class Portfolio extends React.Component {
    render() {
        return (
            <section className="page-section portfolio" id="portfolio">
                <div className="container">
                    {/* <!-- Portfolio Section Heading--> */}
                    <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">My free-time projects</h2>
                    {/* <!-- Icon Divider--> */}
                    <div className="divider-custom">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                        <div className="divider-custom-line"></div>
                    </div>
                    <p className="text-center text-secondary">
                        there's plenty of stuff in blog
                    </p>
                    {/* <!-- Portfolio Grid Items--> */}
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-lg-4">
                            <a 
                            href="https://store.steampowered.com/app/1771240/Piano_Rocker/?beta=1" 
                            target="_blank"
                            className="portfolio-item mx-auto">
                                <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className="portfolio-item-caption-content text-center text-white">
                                            Piano Rocker is a rhythm game designed with MIDI Controllers and e-pianos in mind.
                                    </div>
                                </div>
                                <img className="img-fluid" src="assets/img/piano-rocker-header.jpeg" alt="" />
                            </a>
                        </div>
                        {/* <!-- Portfolio Item 1--> */}
                        <div className="col-md-6 col-lg-4 mb-5">
                            <a href="https://berlin-action-boys.com/"
                                target="_blank"
                                className="portfolio-item mx-auto">
                                <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className="portfolio-item-caption-content text-center text-white">
                                        <p>Berlin action boys. Dot com. It's a sporty lifestyle blog, or something.</p>
                                        <i className="fas fa-plus fa-3x"></i>
                                    </div>
                                </div>
                                <img style={{ height: '240px', objectFit: 'cover' }} className="img-fluid" src="https://berlin-action-boys.com/images/mini.jpeg" alt="" />
                            </a>
                        </div>
                        {/* <!-- Portfolio Item 4--> */}
                        <div className="col-md-6 col-lg-4 mb-5">
                            <a className="portfolio-item mx-auto" target="_blank" href="https://www.roman-guivan.online/en/serverless-dynamodb/">
                                <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className="portfolio-item-caption-content text-center text-white">
                                        <p>My favorite movies list website + SOURCE</p>
                                        <i className="fas fa-plus fa-3x"></i>
                                    </div>
                                </div>
                                <img className="img-fluid" style={{ height: '240px', objectFit: 'cover' }} src="assets/img/portfolio/movies.jpg" alt="" />
                            </a>
                        </div>
                        {/* <!-- Portfolio Item 5--> */}
                        <div className="col-md-6 col-lg-4 mb-5">
                            <a className="portfolio-item mx-auto" target="_blank" href="https://roman-guivan.online/old-site/three-snake/index.htm">
                                <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className="portfolio-item-caption-content text-center text-white">
                                        <p> THREE.js sname game i made in 2016</p>
                                        <i className="fas fa-plus fa-3x"></i>
                                    </div>
                                </div>
                                <img className="img-fluid" style={{ height: '240px', objectFit: 'cover', width: '100%' }} src="assets/img/portfolio/3dsnake.png" alt="" />
                            </a>
                        </div>
                        {/* <!-- Portfolio Item 2--> */}
                        <div className="col-md-6 col-lg-4 mb-5">
                            <a className="portfolio-item mx-auto" data-toggle="modal" data-target="#portfolioModal2">
                                <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className="portfolio-item-caption-content text-center text-white">
                                        <p>A simple 3D volleyball in unity</p>
                                        <i className="fas fa-plus fa-3x"></i>
                                    </div>
                                </div>
                                <img className="img-fluid" style={{ height: '240px', objectFit: 'cover' }} src="assets/img/portfolio/volleyball.jpg" alt="" />
                            </a>
                        </div>
                        {/* <!-- Portfolio Item 3--> */}
                        <div className="col-md-6 col-lg-4 mb-5">
                            <a className="portfolio-item mx-auto" data-toggle="modal" data-target="#portfolioModal2">
                                <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className="portfolio-item-caption-content text-center text-white">
                                        <p>A stealth game demo</p>
                                        <i className="fas fa-plus fa-3x"></i>
                                    </div>
                                </div>
                                <img className="img-fluid" style={{ height: '240px', objectFit: 'cover' }} src="https://i.imgur.com/8LB0h5N.png" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}